<template>
    <div>
        <div class="elem">
            <div class="site-wrap" id="home-section">
                <topHeader/>
                <PreLoader v-if="isLoading"></PreLoader>
                <v-card
                    class="elem__cards"
                >
                    <v-card-title>
                        <v-row>
                            <v-col md="6" cols="6">
                                <h5 style="font-size:22px;font-weight:600;">{{item.title}}</h5>
                            </v-col>
                            <v-col md="6" cols="6" align="end">
                                <v-btn style="color:#fff;" color="#f0bd30" @click="goTo">Артка кайтуу</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-container fluid>
                        <v-row dense>
                            <v-col md="2" sm="2"  cols="12"
                            lg="2"></v-col>
                            <v-col
                            md="8" sm="8" xs="12" cols="12"
                            lg="8"
                            >
                                <v-carousel
                                    style="border-radius:10px;"
                                    cycle
                                    height="400"
                                    hide-delimiter-background
                                    show-arrows-on-hover
                                    
                                >
                                    <template v-slot:prev="{ on, attrs }">
                                    <v-btn
                                        color="success"
                                        v-bind="attrs"
                                        v-on="on"
                                    >Previous word</v-btn>
                                    </template>
                                    <template v-slot:next="{ on, attrs }">
                                    <v-btn
                                        color="info"
                                        v-bind="attrs"
                                        v-on="on"
                                    >Next word</v-btn>
                                    </template>
                                    <v-carousel-item
                                    v-for="(slide, i) in item.words"
                                    :key="i"
                                    >
                                    <v-sheet
                                        :color="'#'+ slide.color"
                                        height="100%"
                                    >
                                        <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center"
                                        >
                                        <v-col md="12" sm="12" lg="12" cols="12" align="center" style="margin:0px -40px -40px -40px;">
                                            <div class="display-1" style="padding:10px;">
                                                {{ slide.transcript }}
                                            </div>
                                        </v-col>
                                        <v-col md="12" sm="12" lg="12" cols="12" align="center" style="margin:-40px -40px -40px -40px;">
                                            <div class="display-2" style="padding:10px;">
                                                {{ slide.translate }}
                                            </div>
                                        </v-col>
                                        <v-col md="12" sm="12" lg="12" cols="12" align="center" style="margin:-40px -40px 20px -40px;">
                                            <div class="display-1" style="padding:20px;">
                                                {{ slide.example }}
                                            </div>
                                        </v-col>
                                        </v-row>
                                    </v-sheet>
                                    </v-carousel-item>
                                </v-carousel>
                            </v-col>
                            <v-col md="2" sm="2"  cols="12"
                            lg="2"></v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <Footer/>
            </div>
        </div>
    </div>
</template>
<script>
import topHeader from '../../views/navigation/topHeader';
import Footer from '../../views/navigation/Footer';
import PreLoader from '../../components/preloader/PreLoader';
import * as firebase from '@/firebase';
export default {
    components: {
      topHeader,
      Footer,
      PreLoader
    },
    data: () => ({
        sharedialog: false,
        isLoading:false,
        sharing: {
            url: 'https://zhyldyzacademy.kg',
            title: 'Say hi to Vite! A brand new, extremely fast development setup for Vue.',
            description: 'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
            quote: 'The hot reload is so fast it\'s near instant. - Evan You',
            hashtags: 'vuejs,vite,javascript',
            twitterUser: 'youyuxi'
        },
        networks: [
            { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#EA4335', mar:'10px',pad:'6px' },
            { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2',mar:'10px',pad:'6px' },
            { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5',mar:'10px',pad:'6px'},
            { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff',mar:'10px',pad:'6px' },
            { network: 'odnoklassniki', name: 'Odnoklassniki', icon: 'fab fah fa-lg fa-odnoklassniki', color: '#ed812b',mar:'10px',pad:'6px' },
            { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc',mar:'10px',pad:'6px' },
            { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2',mar:'10px',pad:'6px' },
            { network: 'vk', name: 'Vk', icon: 'fab fah fa-lg fa-vk', color: '#4a76a8',mar:'10px',pad:'6px' },
            { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366',mar:'10px',pad:'6px' },
        ],
        colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
        ],
        slides: [
          'First',
          'Second',
          'Third',
          'Fourth',
          'Fifth',
        ],
        item:{},
        four:4,
        interval:1000
    }),
    created() {
        this.getTest();
    },
    methods: {
        async getTest() {
            this.isLoading = true;
            this.item = await firebase.pVocabularyCollection.doc(this.$route.params.testId).get()
                .then((snapshot) => {
                    return (snapshot.data())  
			})
            this.item.words = this.item.words.map((item)=>{
                var randomColor = Math.floor(Math.random()*16777215).toString(16);
                item.color = randomColor;
                return item;
            })
            //this.item.tId = this.$route.params.testId;
            setTimeout(()=>{
                this.isLoading = false;
            },400)
        },
        goTo(){
            this.$router.push({ path: '/admin-page/pre-intermediate/vocabulary'});
        }
    },
    
}
</script>
<style lang="scss" scoped>
.v-application a {
    color: #fff  !important;
    & span {
        margin:10px;
    }
}
</style>